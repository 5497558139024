
import React, { useState } from 'react'
import FormField from './FormField';

const CustomCardDropdown = ({ dropdownOptions,setForm,form, name,value,setValue,onChange,border, width, color }) => {
    const [toggle, setToggle] = useState(false);
    // const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);

    return (
        
        <div style={{display:'inline-block',transform:'scale(0.6)',width:'140px'}} className='text-center'>
        <FormField
            // label={" Speciality Area:"}
            type={"select"}
            // name={'subdomain'}
            // disabled={!domain}
            className={'w-100'}
            value={value}
            onChange={(e)=>{
                setForm && setForm({...form,[name]:e.target.value})
                setValue && setValue(e.target.value)
      
          }}            
            options={dropdownOptions?.map((item) => ({
              label: item,
                value: item,
            }))}
          />
            {/* <div className='position-relative '
                style={{
                    width: width ? width : '100%',
                    zIndex:9999
                }}
            >
          
                <p
                    className='my-0 py-0'
                    style={{
                        paddingLeft: '0.25rem',
                        paddingRight: '1rem',
                        border: border ? border : '0.5px solid rgba(128, 152, 249, 1)',
                        borderRadius: '5px',

                        backgroundImage: 'url(/icons/icon-arrow.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right 0.3rem top 50%',
                        backgroundSize: '0.65rem auto',

                        color: color ? color : 'rgba(128, 152, 249, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        zIndex:9999
                    }}
                    onClick={() => setToggle(p => !p)}
                >{value}</p>
                {toggle && <div
                    className='w-100 bg-white'
                    
                    style={{
                        position: 'absolute',
                        top: '1.5rem',
                        border: '0.4px solid #F0F0F0',
                        boxShadow: '0px 4px 42px 0px #0000001F',
                        borderRadius: '0.5rem',
                        zIndex: 9999
                    }}
                >

                   
                    {dropdownOptions?.map((dOption, idx) => <div key={idx}>
                            {idx !== 0 && <hr className='py-0 my-0 w-75 mx-auto' />}
                            <p
                                onClick={() => {
                                    // setSelectedOption(dropdownOptions[dOption]);
                                    // setForm({ ...form, 'difficultyLevel': dOption });
                                    setForm && setForm({...form,[name]:dOption})
                                    setValue && setValue(dOption)
                                  
                                    setToggle(false);
                                }}
                                className='w-100 py-2 my-0'
                                style={{
                                    fontSize: '10px',
                                    fontWeight: 600,
                                    lineHeight: '10px',
                                    letterSpacing: '-0.02em',
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}
                            >
                                {dOption}
                            </p>
                        </div>
                    )}
                </div>}
            </div> */}
        </div>
        
    )
}

export default CustomCardDropdown