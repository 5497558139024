import React, { useEffect, useState } from "react";
import {
  CustomTable,
  DeleteButton,
  MotionDiv,
  ViewButton,
} from "../../components";
import { Table, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useDeletePageByIdMutation,
  useGetDomainsMutation,
  useGetPagesMutation,
  useGetSubdomainsMutation,
} from "../../features/apiSlice";
import { EditButton } from "../../components/layout/CustomTable";
import { getError } from "../../utils/error";
import { fetchDomains, fetchSubdomains } from "../../utils/apis";
import {
  selectOptions,
  setDomain,
  setSubdomain,
  unsetOptions,
  unsetSubtopic,
  unsetTopic,
} from "../../features/optionSlice";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../components/layout/FormField";
import ModalTemplate from "../../components/modals/ModalTemplate";
import { toast } from "react-toastify";
import FaqPage from "../FAQ/FaqPage";

function GetPages() {
  const navigate = useNavigate();
  const [getPages, { isLoading }] = useGetPagesMutation();
  const [getDomains, { isLoading: domainLoading }] = useGetDomainsMutation();
  const [deletePage, { isLoading: deleteLoading }] =
    useDeletePageByIdMutation();
  const [getSubdomains, { isLoading: subdomainLoading }] =
    useGetSubdomainsMutation();

  const [pages, setPages] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(5);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  const { domain, subdomain } = useSelector(selectOptions);
  const [selectedPage, setSelectedPage] = useState(null);
  const [domains, setDomains] = useState([]);
  const [subdomains, setSubdomains] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const handleShowCancelModal = () => setShowCancelModal(true);
  const handleHideCancelModal = () => setShowCancelModal(false);
  const curPageHandler = (p) => setCurPage(p);

  const numOfPages = Math.ceil(1);
  console.log("no of Page", numOfPages, resultPerPage);

  const column = ["No.", "Page", "Actions"];

  const fetchPages = async () => {
    try {
      const data = await getPages({ id: subdomain }).unwrap();
      console.log(data);
      setPages(data?.pages);
    } catch (error) {
      getError(error);
    }
  };

  const handleDeletePage = async () => {
    try {
      const data = await deletePage(selectedPage?._id).unwrap();
      toast.success(data?.message);
      handleHideCancelModal();
      setSelectedPage(null);
      fetchPages();
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    fetchDomains({ getDomains, setDomains });
  }, []);

  useEffect(() => {
    if (domain) {
      fetchSubdomains({ getSubdomains, setSubdomains, id: domain });
    }
  }, [domain]);

  useEffect(() => {
    if (subdomain) {
      fetchPages();
    }
  }, [subdomain, query]);

  useEffect(() => {
    if (!domain || !domains?.some((d) => d?._id === domain)) {
      if (domains?.length > 0) {
        dispatch(setDomain(domains[0]?._id));
      }
    }
  }, [domains]);

  useEffect(() => {
    if (!subdomain || !subdomains?.some((sd) => sd?._id === subdomain)) {
      if (subdomains?.length > 0) {
        dispatch(setSubdomain(subdomains[0]?._id));
      }
    }
  }, [subdomains]);

  return (
    <MotionDiv>
      {/* <h3>Content Management</h3> */}

      

      <Row>
        <Col sm={4}>
          <FormField
            label={" Domain:"}
            type={"select"}
            name={"domain"}
            value={domain}
            onChange={(e) => {
              dispatch(unsetOptions());
              dispatch(setDomain(e.target.value));
            }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col sm={4}>
          <FormField
            label={" Speciality:"}
            type={"select"}
            name={"subdomain"}
            disabled={!domain}
            value={subdomain}
            onChange={(e) => {
              dispatch(unsetTopic());
              dispatch(unsetSubtopic());
              dispatch(setSubdomain(e.target.value));
            }}
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
              value: item?._id,
            }))}
          />
        </Col>
      </Row>

      {subdomain ?   
      <CustomTable
        loading={isLoading}
        column={["Page","Action"]}
        rowNo={resultPerPage}
        rowProps={{ setResultPerPage }}
        paging={false}
        pageProps={{ numOfPages, curPage }}
        pageHandler={curPageHandler}
        //    search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
        isTitle="true"
        title="Website Content"
        isCreateBtn={false}
       
      >
        
            <tr className="odd">
              <td>Website Content</td>

              <td className="text-center">
                <EditButton
                  onClick={() =>
                    navigate(`/admin/content-management/website-content/${subdomain}`)
                  }
                />
               
              </td>
            </tr>
        
      </CustomTable>
        :
        null
                  }

      <FaqPage subdomain={subdomain}/>


      <CustomTable
        loading={isLoading}
        column={column}
        rowNo={resultPerPage}
        rowProps={{ setResultPerPage }}
        paging={false}
        pageProps={{ numOfPages, curPage }}
        pageHandler={curPageHandler}
        //    search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
        isTitle="true"
        title="Content Pages"
        isCreateBtn={true}
        createBtnProps={{
          text: "New Page.",
          createURL: "/admin/content-management/add-page",
        }}
      >
        {pages &&
          pages?.map((page, i) => (
            <tr key={page?._id} className="odd">
              <td className="text-center">{i + 1}</td>
              <td>{page?.title}</td>

              <td className="text-center">
                <EditButton
                  onClick={() =>
                    navigate(`/admin/content-management/edit-page/${page?._id}`)
                  }
                />
                <DeleteButton
                  onClick={() => {
                    setSelectedPage(page);
                    handleShowCancelModal();
                  }}
                />
              </td>
            </tr>
          ))}
      </CustomTable>



      <ModalTemplate
        title={"Are you sure you want to delete this page?"}
        src={"/icons/question-red.png"}
        onDiscard={handleHideCancelModal}
        show={showCancelModal}
        onHide={handleHideCancelModal}
        onConfirm={handleDeletePage}
        loading={deleteLoading}
        deleteBtn={true}
      />



    </MotionDiv>
  );
}

export default GetPages;
