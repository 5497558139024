import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row,Spinner } from "react-bootstrap";
import FormField from "../../components/layout/FormField";
import { MotionDiv } from "../../components";
import { FaCalendarAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import { AddButton } from "../../components/layout/CustomTable";
import { useLocation } from "react-router-dom";
import ModalTemplate from "../../components/modals/ModalTemplate";
import { useNavigate,useParams} from 'react-router-dom'
import { FaRegCircleCheck } from 'react-icons/fa6';
import { useCreateSubdomainMutation, useDeleteSubdomainByIdMutation, useGetDomainsMutation, useGetSubdomainByIdMutation, useUpdateSubdomainByIdMutation } from "../../features/apiSlice";
import { getError } from "../../utils/error";
import { toast } from "react-toastify";
import { fetchDomains } from "../../utils/apis";
import { FiTrash } from "react-icons/fi";
function AddEditDomain() {

  const navigate = useNavigate();
  const [getDomains] = useGetDomainsMutation();
  const [createSubdomain,{isLoading:subdomainLoading}] = useCreateSubdomainMutation();
  const [updateSubdomainById,{isLoading:updateSubdomainLoading}] = useUpdateSubdomainByIdMutation();
  const [getSubdomainById,{isLoading}] = useGetSubdomainByIdMutation();
  const [deleteSubdomainById,{isLoading:deleteSubdomainLoading}] = useDeleteSubdomainByIdMutation();
  const {id} = useParams();
  const [showModal,setShowModal] = useState(false);
  const [form,setForm] = useState({});
  const [editingPlanIndex, setEditingPlanIndex] = useState(null);
  const [features,setFeatures]= useState([])

  const [selectedPlan,setSelectedPlan] = useState(null);
  const [showPlanDeleteModal,setShowPlanDeleteModal] = useState(false);

    const handleShowModal = (index) => {
      if (index !== undefined) {
        setEditingPlanIndex(index);
        setValidity(plans[index].validity);
        setPrice(plans[index].price);
        setFeatures(plans[index].features);
      } else {
        setEditingPlanIndex(null);
        setValidity('');
        setPrice('');
        setFeatures([]);
      }
      setShowModal(true);
    };
  
  
    const handleCloseModal = () => {
      setShowModal(false);
      setEditingPlanIndex(null);
      setValidity('');
      setPrice('');
      setFeatures([]);
    };
    
  const [domains,setDomains] = useState([]);
  const [plans,setPlans] = useState([]);
  const [validity,setValidity] = useState('');
  const [price,setPrice] = useState('');
  const [showCancelModal,setShowCancelModal] = useState(false);
  const handleShowCancelModal = ()=>setShowCancelModal(true);
  const handleCloseCancelModal = ()=>setShowCancelModal(false);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  const handleShowDeleteModal = ()=>setShowDeleteModal(true);
  const handleCloseDeleteModal = ()=>setShowDeleteModal(false);
  const location = useLocation()
  const isEditDomain = location.pathname.includes('edit-specialty')

  const handleDeleteSubdomain = async()=>{
    try {
      const data = await deleteSubdomainById(id).unwrap();
      console.log(data);
      toast.success(data?.message);
      navigate(-1);
    } catch (error) {
      getError(error);
      
    }
  }

  useEffect(()=>{
     fetchDomains({setDomains,getDomains});
     if(id){
        fetchSubdomain()
     }
  },[])

  const handleCancelConfirm = ()=>{
      handleCloseCancelModal();
      navigate(-1);
  }


  // const handleAddPlan = ()=>{
  //      setPlans((prev)=>[...prev,{validity,price}]);
  //      console.log(plans);
  // }
  const handleAddPlan = () => {
    if(price){
        if(validity){
          const updatedFeatures = features?.filter(feature => feature.trim() !== '');
          if(updatedFeatures?.length >0){
            if (editingPlanIndex !== null) {
              const updatedPlans = [...plans];
              updatedPlans[editingPlanIndex] = { _id: plans[editingPlanIndex]?._id,validity,features:updatedFeatures, price };
              setPlans(updatedPlans);
          
            } else {
              setPlans(prev => [...prev, { _id:null,validity, price ,features:updatedFeatures}]);
            
            }
            handleCloseModal();
          }else{
            toast.warn('Please add atleast one plan feature.')
          }
         
        }else{
          toast.warn('Please Add Validity')
        }
    }else{
      toast.warn('Please Add Price')
    }
   
  };

  // const handleEditPlan = (index) => {
  //   handleShowModal(index);
  // };

  const fetchSubdomain = async()=>{
      try {
        const data = await getSubdomainById(id).unwrap();
        console.log(data);
        const {subDomain} = data;
        
        setForm({
          url: subDomain?.domain_url,
          domain: subDomain?.domain_reference,
          subdomain: subDomain?.sub_domain_name,
          description: subDomain?.description,
        })
        // setPlans(subDomain?.plans)
        if (subDomain?.plans) {
          const updatedPlans = subDomain?.plans?.map(plan => ({ ...plan, _id: plan._id }));
          setPlans(updatedPlans);
        } else {
          setPlans([]);
        }
        

      } catch (error) {
        getError(error)
      }
  }

  const handleDeletePlan = (index) => {
    const updatedPlans = plans.filter((_, i) => i !== index);
    setPlans(updatedPlans);
    setShowPlanDeleteModal(false);
  };

  console.log(plans);

  const handlesubmit = async(e)=>{
    e.preventDefault();
    try {
     
      const data = id? await updateSubdomainById({id:id,data:{
        domain_url: form?.url,
        sub_domain_name: form?.subdomain,
        domain_reference: form?.domain,
        description: form?.description,
        plans:plans,
      }}).unwrap()
      : await createSubdomain({
        domain_url: form?.url,
        sub_domain_name: form?.subdomain,
        domain_reference: form?.domain,
        description: form?.description,
        plans:plans,
      }).unwrap()

      console.log(data);
      toast.success(data?.message);
      navigate(-1)
      // console.log(form);
      // console.log(plans);
    } catch (error) {
      getError(error);
    }
  }

    const addFeature = () => {
        const newFeatures = [...features, ''];
        setFeatures(newFeatures);
      };
    
      const removeFeature = (index) => {
        const newFeatures = [...features];
        newFeatures.splice(index, 1);
        setFeatures(newFeatures);
      };
    
      const handleFeatureChange = (index, value) => {
        const newFeatures = [...features];
        newFeatures[index] = value;
        setFeatures(newFeatures);
      };

    const handleChange = (e) => {
      const { name, value } = e.target;
      console.log(name,value)
      setForm({ ...form, [name]: value });
    };
    
    useEffect(()=>{
 console.log(form)
    },[form])


  

  return (
    <MotionDiv >
        <Form
         onSubmit={handlesubmit}
         >
      <Container className="px-md-5">
           <h3>{isEditDomain?'Edit Area Of Specialty':'Add Area Of Specialty'}</h3>
      <Row>
        <Col md={6}>
        <FormField
            label={" Domain"}
            type={"select"}
            name={'domain'}
            loading={isLoading}
            value={form?.domain}
            onChange={handleChange}
            options={ domains?.map((item) => ({
                label: item?.domain_name,
                value: item?._id,
              }))}
              // onChange={(e) => {
              //   const {  value, label } = e.target;
              //  console.log(value,label)
                  
              // }}
          />
        </Col>
        <Col md={6}>
          <FormField
            label={"Enter Area Of Specialty"}
            placeholder={"Area of specialty"}
            type={"text"}
            loading={isLoading}
            name={'subdomain'}
            value={form?.subdomain}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <FormField
            label={"Enter URL"}
            placeholder={"example.com"}
            type={"text"}
            loading={isLoading}
            value={form?.url}
            disabled={id}
            name={'url'}
            onChange={handleChange}
          />
        </Col>
      </Row>
     


     


      {/* <h6 className="fw-bold">Information About Course</h6> */}
      {/* <p>Information About Course</p> */}
      <Row>
        <Col >
          <FormField
            label={"Information About Course"}
            type={"text"}
            as={"textarea"}
            value={form?.description}
            onChange={handleChange}
            name={'description'}
            rows={5}
            placeholder={"Text goes here..."}
          />
        </Col>
      </Row>
    
    <Row>
      <Col md={6}>
       <h6>Add Plans For This Domain</h6>
      
      </Col>
      {plans?.length > 0 && plans?.length<3? 
      
      
      
      <Col className="text-end" style={{transform:'scale(0.8)'}}>
      <AddButton title={'Add New Plan'} func={handleShowModal} />
      </Col>
      : null}
    </Row>

       <Row className=" my-4 text-center justify-content-center" >
       

        {plans?.length > 0? 
        
        plans?.map((plan,i)=>(
           <Col key={i} md={4}  className="p-1">
           <Card className="m-1 h-100 rounded-3">
            <Card.Body>
              <h3 style={{color:'rgba(97, 114, 243, 1)'}}>£{plan?.price}</h3>
              <p>{plan?.validity}</p>
              <Row><Col>
              
              <Button variant="transparent" className="m-1 w-100 add-btn" onClick={()=>handleShowModal(i)}>Edit Plan</Button>
              </Col></Row>
              
              <Row>
                <Col className="text-start pt-3">
                <h6 className="">Features:</h6>
                  {plan?.features?.map((feature,i)=>(

                   <p key={i}><FaRegCircleCheck color="rgba(0, 0, 139, 1)"/> {feature}</p>
                  ))}
                   {/* <p><FaRegCircleCheck color="rgba(0, 0, 139, 1)"/> 2-Self Assessments </p>
                   <p><FaRegCircleCheck color="rgba(0, 0, 139, 1)"/> One Time reset option </p>
                   */}
                </Col>
              </Row>
             
             
            </Card.Body>
            <Card.Footer className="border-0 bg-white rounded-3">
 
            <Button variant="transparent" className="del-btn m-1 w-100" onClick={()=>{
                setSelectedPlan(i)
                setShowPlanDeleteModal(true)}
                }>Delete Plan </Button>
            </Card.Footer>
           </Card>
           </Col>

        ))
        
        : 
         <Col md={4}  className="d-flex justify-content-center align-items-center ">
        <Card className="border text-center w-100 rounded-3 p-3 py-5" >
        <div className="text-center"><FaCalendarAlt size={40}/></div> 
        There are no plans added
        <div style={{transform:'scale(0.8)'}}>
        <AddButton title={'Add New Plan'} func={handleShowModal} />
        </div>
  </Card>
        </Col>
        }
          


      
       </Row>
        </Container>
        <hr/>
        <Row className="my-3">
            <Col className="text-center ">
            {isEditDomain &&
            
                <Button variant="transparent" style={{right:'10px'}} className="position-absolute  del-btn m-1 px-3" onClick={handleShowDeleteModal}>
                {deleteSubdomainLoading ? <Spinner size="sm" /> :
                  'Delete Specialty'}
                </Button>
            }
               <Button variant="transparent" className="del-btn m-1 px-3" onClick={handleShowCancelModal}>Cancel</Button>
               <Button variant="transparent" type="submit" disabled={subdomainLoading || updateSubdomainLoading} className="add-btn m-1 px-3">
                {subdomainLoading || updateSubdomainLoading ? <Spinner size="sm" /> :
                  isEditDomain?'Save Details' :'Save Details'}
                  </Button>
            </Col>
           
        </Row>
     </Form>

        <Modal show={showModal} onHide={handleCloseModal} size="lg" >
            
            <Modal.Body>
           
                
            <h5 className="text-center my-3">{editingPlanIndex !== null ? 'Edit Plan' : 'Add New Plan'}</h5>
     <hr/>
               
                <Row>
                    <Col sm={6}>
                         <Row>
                            <Col>
                            {/* <FormField label={'Enter Plan Validity'} value={validity} onChange={(e)=>setValidity(e.target.value)} type={'text'} placeholder={'30 days'} /> */}
                            <FormField
            label={"Choose Plan Validity"}
            type={"select"}
            
            value={validity}
            onChange={(e)=>setValidity(e.target.value)}
            
            options={[
              { label: "Monthly", value: "Monthly" },
              { label: "Quarterly", value: "Quarterly" },
              { label: "Annually", value: "Annually" },
            ]}
          />
                            </Col>
                         </Row>
                         <Row>
                            <Col>
                            <FormField label={'Enter cost of Plan (£)'} min={0} value={price}
                            onChange={(e) => {
                              // Ensure the value is a positive number
                              const newValue = e.target.value;
                              if (newValue === '' || Number(newValue) >= 0) {
                                setPrice(newValue);
                              }
                            }} 
                            // onChange={(e)=>setPrice(e.target.value)}
                             type={'number'} placeholder={'£310'} />
                            </Col>
                         </Row>
                    </Col>
                    <Col>

                    <div className='w-100'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <p className='my-0'>Plan Features:</p>
                            <Button
                            variant="transparent"
                                className='add-btn p-1'
                                style={{
                                    fontSize:'0.9rem'
                                }}
                                onClick={addFeature}
                            > <FaPlus className="mb-1"/> Add Feature</Button>

                        </div>
                        <hr />
                        {features?.map((reference, index) => (
        <div className="" key={index} style={{ display: 'flex' }}>
         
         <div style={{transform:'scale(0.9)'}} className="w-100"> <FormField
            type={'text'}
            value={reference}
            onChange={(e) => handleFeatureChange(index, e.target.value)}
            
/>
</div>
          {/* <input
            type="text"
            value={reference}
            onChange={(e) => handleFeatureChange(index, e.target.value)}
            style={{ marginRight: '10px' ,border:'1px solid lightgray'}}
            className='form-field rounded w-100'
          /> */}
          <Button variant="transparent" className=' p-0 mb-3 mt-0' onClick={() => removeFeature(index)}>
          <FiTrash color="var(--danger-red)"/>
        
          </Button>
          
        </div>
        
      ))}
                        {/* <div>
                            <p>
                                <input className='me-2' type="checkbox" name="" id="" />
                                Full QBank Access
                            </p>
                            <p>
                                <input className='me-2' type="checkbox" name="" id="" />
                                2-Self Assessments
                            </p>
                            <p>
                                <input className='me-2' type="checkbox" name="" id="" />
                                One- Time reset option
                            </p>
                            <p>
                                <input className='me-2' type="checkbox" name="" id="" />
                                Enter Feature
                            </p>
                        </div> */}
                    </div>
                         {/* <p>Select Plan Feature From Below</p>
                         <hr/>
                         <Form.Check label={'Full QBank Access'} />
                         <Form.Check label={'2-Self Assessment'} />
                         <Form.Check label={'One-time reset option'} /> */}
                        
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
            <Row className="my-3">
            <Col className="text-center ">
               <Button variant="transparent" className="del-btn m-1 px-3" onClick={handleCloseModal}>Cancel</Button>
               <Button variant="transparent" className="add-btn m-1 px-3" onClick={handleAddPlan}> {editingPlanIndex !== null ?'Save':'Save'}</Button>
            </Col>
           
        </Row>
            </Modal.Footer>
        </Modal>

       
       <ModalTemplate
    show={showCancelModal}
    onHide={handleCloseCancelModal}
       title={`Are you sure you want to cancel ${isEditDomain?'editing':'adding'} specialty?`}
       src={'/icons/question-red.png'}
       onDiscard={handleCloseCancelModal}
       onConfirm={handleCancelConfirm}

/>

<ModalTemplate
    title={"Are you sure you want to delete this plan?"}
    src={'/icons/question-red.png'}
    onDiscard={()=>setShowPlanDeleteModal(false)}
    show={showPlanDeleteModal}
    onHide={()=>setShowPlanDeleteModal(false)}
    onConfirm={()=>handleDeletePlan(selectedPlan)}
    deleteBtn={true}
/>
<ModalTemplate
    title={"Are you sure you want to delete this subdomain?"}
    src={'/icons/question-red.png'}
    onDiscard={handleCloseDeleteModal}
    show={showDeleteModal}
    onHide={handleCloseDeleteModal}
    onConfirm={handleDeleteSubdomain}
    loading={deleteSubdomainLoading}
    deleteBtn={true}
/>

    </MotionDiv>
  );
}

export default AddEditDomain;
