import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";
import FormField from "../../components/layout/FormField";
import { MotionDiv } from "../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalTemplate from "../../components/modals/ModalTemplate";
import {
  useCreateProfMutation,
  useGetDomainsMutation,
  useGetProfByIdMutation,
  useGetSubdomainsMutation,
  useUpdateProfByIdMutation,
} from "../../features/apiSlice";
import { getError } from "../../utils/error";
import { FaRegRectangleXmark } from "react-icons/fa6";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";
import { fetchSubdomains } from "../../utils/apis";

function AddEditProf() {
  const location = useLocation();
  const navigate = useNavigate();

  const isEditProf = location.pathname.includes("edit-prof");
  const [getDomain] = useGetDomainsMutation();
  const [getSubdomains] = useGetSubdomainsMutation();
  const [createProf, { isLoading }] = useCreateProfMutation();
  const [getProfById, { isLoading: getLoading }] = useGetProfByIdMutation();
  const [updateProfById, { isLoading: updateLoading }] =
    useUpdateProfByIdMutation();
  const [isFetchProfile, setIsFetchProfile] = useState(false);
  const [form, setForm] = useState({
    countryCode: 'gb'
  });
  const [domains, setDomains] = useState([]);
  const [subdomains, setSubdomains] = useState([]);
  const [selectedSubdomains, setSelectedSubdomains] = useState([]);
  const { id } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);
  const [showCancelModal,setShowCancelModal] = useState(false);
  const handleShowCancelModal = ()=>setShowCancelModal(true);
  const handleCloseCancelModal = ()=>setShowCancelModal(false);
  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const handleShowCreatedModal = () =>{
     setShowCreatedModal(true);
      setTimeout(()=>(
          handleHideCreatedModal()
      ),3000)
    }
  const handleHideCreatedModal = () => {
    setShowCreatedModal(false);
    navigate("/admin/profs");
  };

  const handleConfirmBtn = () => {
    handleSubmit();
    // handleHideAddModal();
    // handleShowCreatedModal();
  };

  const handleCancelConfirm = ()=>{
    handleCloseCancelModal();
    navigate(-1);
}

  const fetchDomains = async () => {
    try {
      const data = await getDomain().unwrap();

      setDomains(data?.domains);
    } catch (error) {
      getError(error);
    }
  };

  const handleFetchSubdomains = async (id) => {
    try {
      console.log('id: ',id)
      // const data = await getSubdomains(id).unwrap();
      fetchSubdomains({ getSubdomains, setSubdomains, id });

      // setSubdomains(data?.subDomains);
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  useEffect(() => {
    if (form?.domain) {
      if (!isFetchProfile) {
        setSelectedSubdomains([]);
      }
      handleFetchSubdomains(form?.domain);
    }
  }, [form?.domain]);

  useEffect(() => {
    if (id) {
      fetchProf();
    }
  }, [id]);

  useEffect(() => {
    if (selectedSubdomains) {
      const updatedsubdomains = subdomains.filter(
        (item) => !selectedSubdomains.map((sub) => sub?._id).includes(item?._id)
      );
      setSubdomains(updatedsubdomains);
    }
  }, [selectedSubdomains]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setForm({ ...form, [name]: value });
  };

  const handleAddSubdomain = (e) => {
    const { name, label, value } = e.target;
    console.log(name, label, value);
    setSelectedSubdomains((prev) => [
      ...prev,
      { sub_domain_name: label, _id: value },
    ]);
  };

  const handleDeleteSubdomain = (item) => {
    const updatedSelectedDomain = selectedSubdomains.filter(
      (s, i) => s?._id !== item?._id
    );
    setSelectedSubdomains(updatedSelectedDomain);
    setSubdomains([
      ...subdomains,
      { sub_domain_name: item?.sub_domain_name, _id: item?._id },
    ]);
  };

  // const domains = [
  //         {name:'Engineering'},
  //         {name:'Medical'},
  // ]
  // const subDomains = [
  //         {name:'Mechanical Engineering'},
  //         {name:'Civil Engineering'},
  // ]

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    handleShowAddModal();
  };

  const fetchProf = async () => {
    try {
      setIsFetchProfile(true);
      const data = await getProfById(id).unwrap();

      console.log(data);
      const prof = data?.professor;
      setForm({
        firstName: prof?.first_name,
        lastName: prof?.last_name,
        email: prof?.email,
        payPercentage: prof?.pay_percent,
        dob: prof?.dob,
        doj: prof?.joining_date,
        mobile: prof?.mobile,
        id: prof?.professor_id,
        addr: prof?.address,
        domain: prof?.domain?._id,
      });

       getCountryCode(prof?.mobile)
      setSelectedSubdomains(prof?.sub_domain);
      //   handleHideAddModal();
      // handleShowCreatedModal();
      setIsFetchProfile(false);
    } catch (error) {
      // setIsFetchProfile(false)
      getError(error);
    }
  };

  console.log(form)
  const getCountryCode = (phoneNumber) => {
    try {
      // const phoneNumberString = phoneNumber.toString();
      const parsedPhoneNumber = parsePhoneNumberFromString(`+${phoneNumber}`);
  
     
      console.log('country code: ',parsedPhoneNumber?.country)
      if (parsedPhoneNumber) {
       
        setForm((prevForm) => ({
          ...prevForm,
          countryCode: parsedPhoneNumber?.country?.toLowerCase(),
      }));
    
    }
  
     
    } catch (error) {
      console.error('Error parsing phone number:', error);
      
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return false;
     } else {
       return true;
    }
};

  const handleSubmit = async (e) => {

    if(form?.mobile){
      if(validateEmail(form?.email)){
        if (form?.payPercentage >= 0 && form?.payPercentage <= 100) {
          const phoneNumber = parsePhoneNumber(form?.mobile, form?.countryCode?.toUpperCase() );
          if (phoneNumber && phoneNumber.isValid()) {
          try {
            const subDomainIds = selectedSubdomains.map((sub) => sub?._id);
            const data = isEditProf
              ? await updateProfById({
                  id: id,
                  data: {
                    first_name: form?.firstName,
                    last_name: form?.lastName,
                    email: form?.email,
                    pay_percent: form?.payPercentage,
                    dob: form?.dob,
                    joining_date: form?.doj,
                    mobile: form?.mobile,
                    professor_id: form?.id,
                    address: form?.addr,
                    domain: form?.domain,
                    sub_domain: subDomainIds,
                  },
                }).unwrap()
              : await createProf({
                  first_name: form?.firstName,
                  last_name: form?.lastName,
                  email: form?.email,
                  password: form?.password,
                  dob: form?.dob,
                  pay_percent: form?.payPercentage,
                  joining_date: form?.doj,
                  mobile: form?.mobile,
                  professor_id: form?.id,
                  address: form?.addr,
                  domain: form?.domain,
                  sub_domain: subDomainIds,
                }).unwrap();
    
            console.log(data);
    
            handleHideAddModal();
            handleShowCreatedModal();
          } catch (error) {
            getError(error);
          }
        }
          else {
            toast.warn('Enter a valid mobile number');
          }
        } else {
          toast.warn("Pay percentage must be between 0 to 100");
        }
      }
        else{
          toast.warn("Enter a valid email");
        }
    }else{
      toast.warn('Please enter mobile number.')
    }
    
  };

  return (
    <MotionDiv>
      <Container className="px-md-5">
      <h4 className="mb-3">{isEditProf ? "Edit Prof." : "Add New Prof."}</h4>
        <Form onSubmit={handleFormSubmit}>

        <Row>
          <Col md={6}>
            <Row>
              <FormField
                label={"First Name"}
                type={"text"}
                placeholder={"First Name"}
                name={"firstName"}
                value={form?.firstName}
                onChange={handleChange}
              />
              </Row>
            <Row>
              <FormField
                label={"Last Name"}
                type={"text"}
                placeholder={"Last Name"}
                name={"lastName"}
                value={form?.lastName}
                onChange={handleChange}
              />
              </Row>
              <Row>
              <FormField
                label={"Email"}
                type={"email"}
                placeholder={"email@example.com"}
                name={"email"}
                value={form?.email}
                onChange={handleChange}
              />
       
              </Row>
              <Row>
              <div className="form-group mb-3">
                        <label className="form-label d-block ">Phone Number</label>
                        <PhoneInput
                        containerClass='form-field ps-2'
                
                        inputClass=" w-100 m-0 border-0 rounded-4 form-field"
                  inputStyle={{height:'2.3rem'}}
                  buttonClass="border-0 rounded-"
                  country={isEditProf?'':form?.countryCode?.toLowerCase()}
                  enableSearch={true}
                  countryCodeEditable={false}
                  value={form?.mobile}
                  onChange={(phone, code) => {
                     
                      setForm({...form,
                        mobile: phone,
                        countryCode: code.countryCode,
                        dialCode: code.dialCode,
                      })
                
                  }}
                />
                </div>
              {/* <FormField
                label={"Phone Number"}
                type={"number"}
                placeholder={"55987456331"}
                name={"mobile"}
                value={form?.mobile}
                onChange={handleChange}
              /> */}
              </Row>
              <Row>
              <FormField
                label={"Date Of Birth"}
                type={"date"}
                placeholder={"00/00/0000"}
                name={"dob"}
                dateMax={true}
                value={form?.dob}
                onChange={handleChange}
              />
              </Row>
              <Row>
              <FormField
                label={"Address"}
                type={"text"}
                placeholder={"101 abc street"}
                name={"addr"}
                value={form?.addr}
                onChange={handleChange}
              />
              </Row>
          </Col>  
          <Col>
      <Row>
      <FormField
                label={"Joining Date"}
                placeholder={"00/00/0000"}
                type={"date"}
                name={"doj"}
                value={form?.doj}
                onChange={handleChange}
              />
      </Row>
<Row>
<FormField
                label={" Domain"}
                type={"select"}
                name={"domain"}
                value={form?.domain}
                onChange={handleChange}
                options={domains?.map((item) => ({
                  label: item?.domain_name,
                  value: item?._id,
                }))}
              />

</Row>
          <Row className="">
            <Col>
          <FormField
                label={" Specialty"}
                type={"select"}
                name={"subdomain"}
                // value={form?.subdomain}
                required={false}
                onChange={handleAddSubdomain}
                options={subdomains?.map((item) => ({
                  label: item?.sub_domain_name,
                  value: item?._id,
                }))}
              />

              {selectedSubdomains?.map((item, i) => (
                <div
                  className="domain-card p-2 my-1 d-flex rounded"
                  style={{ color: "rgba(0, 0, 139, 1)" }}
                  key={item._id}
                >
                  <div className="row w-100">
                    <div className="col">{item?.sub_domain_name}</div>
                    <div className="col-auto">
                      <FaRegRectangleXmark
                        onClick={() => handleDeleteSubdomain(item)}
                        style={{ cursor: "pointer" }}
                        color="rgba(255, 22, 22, 1)"
                      />
                    </div>
                  </div>
                </div>
              ))}
              </Col>
          </Row>
          <Row className="mt-2">
            <Form.Group>
              <label for="volume">Pay Percentage</label> <br />
              {/* <input type="range"   value={form?.payPercentage}  id="volume" onChange={handleChange} name="payPercentage" min="0" max="100"/> */}
              {/* </Col>
         <Col md={1} className="d-flex align-items-end"> */}
              <input
                type="number"
                className=" form-field p-0  text-center  mt-2"
                style={{ color: "var(--primary-color)", width: "4rem" }}
                value={form?.payPercentage}
                placeholder="50%"
                onChange={handleChange}
                name="payPercentage"
                min="0"
                max="100"
              />
              <span style={{ color: "var(--primary-color)" }} className="ms-1 fw-bold">%</span>
          </Form.Group>
          </Row>
          </Col>  
        </Row>  
         
         
          
          <hr />
          <Row>
            <Col md={6}>
              <FormField
                label={"Prof. ID"}
                type={"text"}
                placeholder={"Abc123"}
                name={"id"}
                value={form?.id}
                onChange={handleChange}
              />
            </Col>
            <Col>
              {isEditProf ? null : (
                <FormField
                  label={"Password"}
                  type={"password"}
                  placeholder={"********"}
                  name={"password"}
                  value={form?.password}
                  onChange={handleChange}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <Button
                variant="transparent"
                className="del-btn px-3 m-1"
                onClick={handleShowCancelModal}
              >
                Cancel
              </Button>
              <Button  className="blue-btn px-3 m-1" type="submit">
                {isEditProf ? "Save Details" : "Save Details"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <ModalTemplate
    show={showCancelModal}
    onHide={handleCloseCancelModal}
       title={`Are you sure you want to cancel ${isEditProf?'editing':'adding'} professor?`}
       src={'/icons/question-red.png'}
       onDiscard={handleCloseCancelModal}
       onConfirm={handleCancelConfirm}

/>
      <ModalTemplate
        show={showAddModal}
        onHide={handleHideAddModal}
        src={"/icons/question-green.png"}
        title={`Are you sure you want to  ${
          isEditProf ? "update" : "add"
        } this Prof. profile to user system ?`}
        
        onConfirm={handleConfirmBtn}
        onDiscard={handleHideAddModal}
        loading={isLoading || updateLoading}
      />

      <ModalTemplate
        show={showCreatedModal}
        onHide={handleHideCreatedModal}
        src={"/icons/tick.png"}
        title={`You have successfully ${
          isEditProf ? "updated" : "created new"
        } Prof. profile`}
      />
    </MotionDiv>
  );
}

export default AddEditProf;
