import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  CustomTable,
  ViewButton,
  DeleteButton,
} from "../../components";
import { getError, toastOptions } from "../../utils/error";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";
import { Col, Row, Form, Dropdown } from "react-bootstrap";
import CountCard from "../../components/layout/CountCard";
import { ReceiptButton } from "../../components/layout/CustomTable";
import { selectAuth } from "../../features/authSlice";
import { useGetAdminTicketMutation } from "../../features/apiSlice";

export default function DetailedTickets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = useSelector(selectAuth);
  const [error, setError] = useState(null);
  // const [users,setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
  const [tickets, setTickets] = useState([]);
  
  const curPageHandler = (p) => setCurPage(p);
  const {stat} = useParams();
  const [status, setStatus] = useState(stat);
  const [getAdminTicket, { isLoading }] = useGetAdminTicketMutation();

  useEffect(() => {
    fetchTickets();
  }, [status, query]);

  const fetchTickets = async () => {
    try {
      const { data } = await getAdminTicket({ status:status.split(' ')[0], query });
      setTickets(data.tickets);
    } catch (error) {
      getError(error);
    }
  };

  // console.log(tickets);

  const numOfPages = Math.ceil(usersCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  // console.log("no of Page", numOfPages, resultPerPage, usersCount);

  const column = [
    "No.",
    "Raised By",
    "Email",
    "Subject ",
    "Speciality",
    "Prof. Name",
    "Status",
    "Actions",
  ];

  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <CustomTable
            loading={isLoading}
            column={column}
            rowNo={resultPerPage}
            rowProps={{ setResultPerPage }}
            paging={numOfPages > 0}
            pageProps={{ numOfPages, curPage }}
            pageHandler={curPageHandler}
            search={true}
            searchProps={{ searchInput, setSearchInput, setQuery }}
            isTitle="true"
            title={
            
      <Dropdown onSelect={(eventKey) => setStatus(eventKey)} className="border d-inline-block rounded-3">
        <Dropdown.Toggle className="fs-6 border rounded-3" style={{color:'#000', maxWidth: "200px", height: "50px" }}>
          {status}
        </Dropdown.Toggle>
        <Dropdown.Menu className="rounded-3 shadow" style={{zIndex:9999}}>
          <Dropdown.Item className={`custom-drop ${status === 'Closed Ticket'?'active':''}`} eventKey="Closed Ticket">
            Closed Ticket
          </Dropdown.Item>
          <Dropdown.Item className={`custom-drop ${status === 'Open Ticket'?'active':''}`} eventKey="Open Ticket">
            Open Ticket
          </Dropdown.Item>
          <Dropdown.Item className={`custom-drop ${status === 'Pending Ticket'?'active':''}`} eventKey="Pending Ticket">
            Pending Ticket
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    
            }
          >
            {tickets &&
              tickets.length > 0 &&
              tickets?.map((ticket, i) => (
                <tr key={ticket?._id} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  {/* <td>{ticket?._id}</td> */}
                  <td>
                    {ticket?.from?.first_name} {ticket?.from?.last_name}
                  </td>
                  <td>{ticket?.from?.email}</td>
                  <td>{ticket?.subject}</td>
                  <td>{ticket?.subdomain?.sub_domain_name}</td>
                  <td>{ticket?.to?.name}</td>
                  <td 
                    
                  >
                  <div className="rounded-pill" 
                  style={{
                    color: `${
                      ticket?.status === "Open"
                        ? "var(--text-completed)"
                        : ticket?.status === "Pending"
                        ? "var(--text-pending)"
                        : "var(--text-closed)"
                    }`,
                    backgroundColor: `${
                      ticket?.status === "Open"
                        ? "var(--bg-completed)"
                        : ticket?.status === "Pending"
                        ? "var(--bg-pending)"
                        : "var(--bg-closed)"
                    }`,
                  }}
                  >{ticket?.status}</div>
                  </td>
                  <td>
                    <ViewButton
                      onClick={() =>
                        navigate(`/admin/ticket/chat/${ticket?._id}`)
                      }
                    />
                  </td>
                </tr>
              ))}
          </CustomTable>
        </>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
