import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const BASE_URL = "https://scienda-backend.onrender.com/api";
const BASE_URL = "https://scienda-backend-dp0q.onrender.com/api";
// const BASE_URL = "http://localhost:5000/api";
export const imgAddr = "https://creative-story.s3.amazonaws.com";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().auth;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (data) => ({
        url: "/upload-image",
        method: "POST",
        body: data,
      }),
    }),
    loginAdmin: builder.mutation({
      query: (data) => ({
        url: "/admin/admin-login",
        method: "POST",
        body: data,
      }),
    }),

    createDomain: builder.mutation({
      query: (data) => ({
        url: "/domain/create-domain",
        method: "POST",
        body: data,
      }),
    }),
    getDomains: builder.mutation({
      query: (params) => ({
        url: `/domain/get-domains?${params ? params : ""}`,
        method: "GET",
      }),
    }),
    getDomainById: builder.mutation({
      query: (id) => ({
        url: `/domain/get-domain/${id}`,
        method: "GET",
      }),
    }),
    updateDomainById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/domain/update-domain/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteDomainById: builder.mutation({
      query: (id) => ({
        url: `/domain/delete-domain/${id}`,
        method: "DELETE",
      }),
    }),

    //--subdomain----

    getSubdomains: builder.mutation({
      query: ({ id, params }) => ({
        url: `/sub-domain/get-sub-domains${id ? `?domain=${id}` : "?"}${
          params ? "&&" + params : ""
        }`,
        method: "GET",
      }),
    }),
    createSubdomain: builder.mutation({
      query: (data) => ({
        url: `/sub-domain/create-sub-domain`,
        method: "POST",
        body: data,
      }),
    }),
    getSubdomainById: builder.mutation({
      query: (id) => ({
        url: `/sub-domain/get-sub-domain/${id}`,
        method: "GET",
      }),
    }),

    updateSubdomainById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/sub-domain/update-sub-domain/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSubdomainById: builder.mutation({
      query: (id) => ({
        url: `/sub-domain/delete-sub-domain/${id}`,
        method: "DELETE",
      }),
    }),

    //--topic---

    getTopics: builder.mutation({
      query: ({ id, params }) => ({
        url: `/topic/get-topics?sub_domain=${id}${params ? "&&" + params : ""}`,
        method: "GET",
      }),
    }),
    createTopic: builder.mutation({
      query: (data) => ({
        url: `/topic/create-topic`,
        method: "POST",
        body: data,
      }),
    }),
    getTopicById: builder.mutation({
      query: (id) => ({
        url: `/topic/get-topic/${id}`,
        method: "GET",
      }),
    }),

    updateTopicById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/topic/update-topic/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteTopicById: builder.mutation({
      query: (id) => ({
        url: `/topic/delete-topic/${id}`,
        method: "DELETE",
      }),
    }),

    //--sub-topic---

    getSubTopics: builder.mutation({
      query: ({ id, params }) => ({
        url: `/subtopic/get-subtopics?topic=${id}${
          params ? "&&" + params : ""
        }`,
        method: "GET",
      }),
    }),
    createSubTopic: builder.mutation({
      query: (data) => ({
        url: `/subtopic/create-subtopic`,
        method: "POST",
        body: data,
      }),
    }),
    getSubTopicById: builder.mutation({
      query: (id) => ({
        url: `/subtopic/get-subtopic/${id}`,
        method: "GET",
      }),
    }),

    updateSubTopicById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/subtopic/update-subtopic/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSubTopicById: builder.mutation({
      query: (id) => ({
        url: `/subtopic/delete-subtopic/${id}`,
        method: "DELETE",
     
      }),
    }),

    //--summary--

    getSummary: builder.mutation({
      query: () => ({
        url: `/domain/view-summary`,
        method: "Get",
      }),
    }),

    //--question apis--

    getQuestions: builder.mutation({
      query: ({ id, params,topic,subdomain,status }) => ({
        url: `/question/get-questions${id?`?sub_topic_reference=${id}`:''}${subdomain?`?subdomain=${subdomain}`:''}${topic ? `?topic=${topic}` : ""}${params ? `&${params}` : ""}${status ? `&status=${status}` : ""}`,
        method: "GET",
      }),
    }),
    createQuestion: builder.mutation({
      query: (data) => ({
        url: `/question/create-question`,
        method: "POST",
        body: data,
      }),
    }),
    getQuestionById: builder.mutation({
      query: (id) => ({
        url: `/question/get-question/${id}`,
        method: "GET",
      }),
    }),

    updateQuestionById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/question/update-question/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteQuestionById: builder.mutation({
      query: (id) => ({
        url: `/question/delete-question/${id}`,
        method: "DELETE",
      }),
    }),

    //--test apis--

    createTest: builder.mutation({
      query: (data) => ({
        url: `/test/create-test`,
        method: "POST",
        body: data,
      }),
    }),
    getTests: builder.mutation({
      query: ({ id, params }) => ({
        url: `/test/get-tests?subdomain_reference=${id}${
          params && `&${params}`
        }`,
        method: "GET",
      }),
    }),
    getTestById: builder.mutation({
      query: (id) => ({
        url: `/test/get-test/${id}`,
        method: "GET",
      }),
    }),
    updateTest: builder.mutation({
      query: ({ id, data }) => {
        console.log('Update Test Mutation:', { id, data });
        return {
          url: `/test/update-test/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteTestById: builder.mutation({
      query: (id) => ({
        url: `/test/delete-test/${id}`,
        method: "DELETE",
      }),
    }),

    //--prof apis---
    createProf: builder.mutation({
      query: (data) => ({
        url: `/subadmin/create-subadmin`,
        method: "POST",
        body: data,
      }),
    }),
    getProfs: builder.mutation({
      query: (query) => ({
        url: `/subadmin/get-subadmins${query}`,
        method: "GET",
      }),
    }),

    getProfById: builder.mutation({
      query: (id) => ({
        url: `/admin/get-subadmin/${id}`,
        method: "GET",
      }),
    }),
    updateProfById: builder.mutation({
      query: ({ id, data }) => {
        // console.log(data);
        return {
          url: `/admin/update-subadmin-profile/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),

    addProfBankDetails: builder.mutation({
      query: (data) => ({
        url: `/bank/add-bank`,
        method: "POST",
        body: data,
      }),
    }),
    blockProf: builder.mutation({
      query: (id) => ({
        url: `/admin/block-professor/${id} `,
        method: "PATCH",
       
      }),
    }),
    unblockProf: builder.mutation({
      query: (id) => ({
        url: `/admin/unblock-professor/${id} `,
        method: "PATCH",
       
      }),
    }),
    deleteProf: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-subadmin/${id} `,
        method: "DELETE",
       
      }),
    }),

    //--content apis----

    createContent: builder.mutation({
      query: (data) => ({
        url: `/website/content`,
        method: "POST",
        body: data
      }),
    }),
    getContent: builder.mutation({
      query: (id) => ({
        url: `/website/content?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getPages: builder.mutation({
      query: ({id}) => ({
        url: `/professor_pages/get-pages?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getPageById: builder.mutation({
      query: (id) => ({
        url: `/professor_pages/get-page/${id}`,
        method: "GET",
      }),
    }),
    deletePageById: builder.mutation({
      query: (id) => ({
        url: `/professor_pages/delete-page/${id}`,
        method: "DELETE",
      }),
    }),
    createPage: builder.mutation({
      query: (data) => ({
        url: `/professor_pages/create-page`,
        method: "POST",
        body: data,
      }),
    }),
    updatePage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/professor_pages/update-page/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),

    //--faq apis--


    createFaq: builder.mutation({
      query: (data) => ({
        url: `/faq/create-faq`,
        method: "POST",
        body: data,
      }),
    }),
    getFaqs: builder.mutation({
      query: ({ id }) => ({
        url: `/faq/get-faqs?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getFaqById: builder.mutation({
      query: (id) => ({
        url: `/faq/get-faq/${id}`,
        method: "GET",
      }),
    }),
    updateFaq: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/faq/update-faq/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteFaqById: builder.mutation({
      query: (id) => ({
        url: `/faq/delete-faq/${id}`,
        method: "DELETE",
      }),
    }),


    //--chat apis----
    getAdminTicket: builder.mutation({
      query: ({ status, query }) => ({
        url: `/admin/get-tickets?status=${status}&key=${query}`,
        method: "GET",
      }),
    }),

    getSingleTicket: builder.mutation({
      query: (id) => ({
        url: `/admin/get-ticket/${id}`,
        method: "GET",
      }),
    }),

    closeTicket: builder.mutation({
      query: (id) => ({
        url: `/admin/close-ticket/${id}`,
        method: "PATCH",
      }),
    }),

    acceptTicket: builder.mutation({
      query: (id) => ({
        url: `/admin/accept-ticket/${id}`,
        method: "PATCH",
      }),
    }),

    deleteTicket: builder.mutation({
      query: (id) => ({
        url: `/ticket/delete-ticket/${id}`,
        method: "DELETE",
      }),
    }),

    postMessage: builder.mutation({
      query: (data) => ({
        url: `/ticket/post-message/${data.ticketId}`,
        method: "PATCH",
        body: data,
      }),
    }),

    //--Report apis----
    getDashboardData: builder.mutation({
      query: () => ({
        url: `/admin/get-admin-dashboard-data`,
        method: "GET",
      }),
    }),
    getAmountData: builder.mutation({
      query: (id) => ({
        url: `/admin/get-payment-graph?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getReportStatics: builder.mutation({
      query: () => ({
        url: `/admin/get-statics`,
        method: "GET",
      }),
    }),
    viewModuleReportStatics: builder.mutation({
      query: (query) => ({
        url: `admin/get-all-transactions?subdomain=${query}`,
        method: "GET",
      }),
    }),

    getReportGraphStatics: builder.mutation({
      query: () => ({
        url: `/admin/get-users-graph-data`,
        method: "GET",
      }),
    }),

    getStats: builder.mutation({
      query: ({id}) => ({
        url: `/subadmin/get-statics?subdomain=${id}&professor=`,
        method: "GET",
      }),
    }),
    getUsersGraph: builder.mutation({
      query: (id) => ({
        url: `/subadmin/get-user-graph-data?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getQueGraph: builder.mutation({
      query: (id) => ({
        url: `subadmin/get-question-graph-data?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getTestGraph: builder.mutation({
      query: (id) => ({
        url: `subadmin/get-test-graph-data?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getTransactionGraph: builder.mutation({
      query: (id) => ({
        url: `subadmin/get-transaction-graph-data?subdomain=${id}`,
        method: "GET",
      }),
    }),
    getSalaryGraph: builder.mutation({
      query: () => ({
        url: `/subadmin/get-salary-graph-data`,
        method: "GET",
      }),
    }),
    getModifiedTests: builder.mutation({
      query: ({id,params}) => ({
        url: `/subadmin/get-modified-test?subdomain=${id}${params && params}`,
        method: "GET",
      }),
    }),
    getModifiedQues: builder.mutation({
      query: ({id,params}) => ({
        url: `/subadmin/get-modified-question?subdomain=${id}${params && params}`,
        method: "GET",
      }),
    }),
    getQueToBeModified: builder.mutation({
      query: ({id,params}) => ({
        url: `/subadmin/question-tobe-modified?subdomain=${id}${params && params}`,
        method: "GET",
      }),
    }),
    getTestsTimedOut: builder.mutation({
      query: ({id,params}) => ({
        url: `/subadmin/timed-out-test?subdomain=${id}${params && params}`,
        method: "GET",
      }),
    }),

    //--User apis----
    getUsers: builder.mutation({
      query: (query) => ({
        url: `/admin/get-users?key=${query}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-user/${id}`,
        method: "DELETE",
      }),
    }),

    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `/admin/get-otp`,
        method: "POST",
        body: data,
      }),
    }),

    submitOTP: builder.mutation({
      query: (data) => ({
        url: `/admin/submit-otp`,
        method: "POST",
        body: data,
      }),
    }),
    resendOTP: builder.mutation({
      query: (data) => ({
        url: `/admin/resend-otp`,
        method: "POST",
        body: data,
      }),
    }),

    newPassword: builder.mutation({
      query: (data) => ({
        url: `/admin/reset-password`,
        method: "POST",
        body: data,
      }),
    }),

    //--Payment apis----
    getPayments: builder.mutation({
      query: (query) => ({
        url: `/admin/get-payments?key=${query}`,
        method: "GET",
      }),
    }),
    refundPayment: builder.mutation({
      query: (id) => ({
        url: `/subadmin/refund/${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useCreateContentMutation,
  useGetContentMutation,
  useUploadImageMutation,
  useGetFaqsMutation,
  useCreateFaqMutation,
  useGetFaqByIdMutation,
  useUpdateFaqMutation,
  useDeleteFaqByIdMutation,
  useDeleteProfMutation,
  useDeleteDomainByIdMutation,
  useDeleteSubdomainByIdMutation,
  useGetAmountDataMutation,
  useDeleteTopicByIdMutation,
  useDeleteSubTopicByIdMutation,
  useDeleteQuestionByIdMutation,
  useDeleteTestByIdMutation,
  useBlockProfMutation,
  useUnblockProfMutation,
  useGetSummaryMutation,
  useGetDashboardDataMutation,
  useGetTransactionGraphMutation,
  useGetStatsMutation,
  useGetQueGraphMutation,
  useGetTestGraphMutation,
  useGetUsersGraphMutation,
  useGetModifiedQuesMutation,
  useGetQueToBeModifiedMutation,
  useGetTestsTimedOutMutation,
  useGetModifiedTestsMutation,
  useGetTestsMutation,
  useCreateTestMutation,
  useGetTestByIdMutation,
  useUpdateTestMutation,
  useCreateQuestionMutation,
  useGetQuestionsMutation,
  useGetQuestionByIdMutation,
  useUpdateQuestionByIdMutation,
  useUpdateSubTopicByIdMutation,
  useGetSubTopicsMutation,
  useCreateSubTopicMutation,
  useGetSubTopicByIdMutation,
  useUpdateTopicByIdMutation,
  useGetTopicByIdMutation,
  useGetTopicsMutation,
  useCreateTopicMutation,
  useUpdateProfByIdMutation,
  useGetProfByIdMutation,
  useCreateProfMutation,
  useGetProfsMutation,
  useGetSubdomainsMutation,
  useGetSubdomainByIdMutation,
  useCreateSubdomainMutation,
  useUpdateSubdomainByIdMutation,
  useGetDomainByIdMutation,
  useUpdateDomainByIdMutation,
  useGetDomainsMutation,
  useCreateDomainMutation,
  useCreatePageMutation,
  useUpdatePageMutation,
  useGetPageByIdMutation,
  useGetPagesMutation,
  useDeletePageByIdMutation,
  useLoginAdminMutation,
  useAddProfBankDetailsMutation,
  useGetAdminTicketMutation,
  useGetSingleTicketMutation,
  usePostMessageMutation,
  useCloseTicketMutation,
  useAcceptTicketMutation,
  useDeleteTicketMutation,
  useGetReportStaticsMutation,
  useGetUsersMutation,
  useGetPaymentsMutation,
  useRefundPaymentMutation,
  useForgetPasswordMutation,
  useNewPasswordMutation,
  useSubmitOTPMutation,
  useResendOTPMutation,
  useGetReportGraphStaticsMutation,
  useViewModuleReportStaticsMutation,
  useDeleteUserMutation,
} = apiSlice;
