import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Image, Row, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getError } from '../../utils/error';
import { MotionDiv } from '../../components';
import { useCreateFaqMutation, useGetDomainsMutation, useGetFaqByIdMutation, useGetSubdomainsMutation, useUpdateFaqMutation } from '../../features/apiSlice';
import FormField from '../../components/layout/FormField';
import { useDispatch, useSelector } from 'react-redux';
import { selectOptions, setDomain, setSubdomain, unsetOptions, unsetSubtopic, unsetTopic } from '../../features/optionSlice';
import { fetchDomains, fetchSubdomains } from '../../utils/apis';

function AddEditFaq() {

    const [form,setForm] = useState({});
  const navigate = useNavigate()
  
    const [getDomains,{isLoading:domainLoading}] = useGetDomainsMutation();
        const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();
     const dispatch = useDispatch();
         const {domain,subdomain} = useSelector(selectOptions)
    
        const [domains,setDomains] = useState([]);
         const [subdomains,setSubdomains] = useState([]);   
         
    const [getFaqById,{isLoading}] = useGetFaqByIdMutation()
    const [updateFaqById,{isLoading:updateLoading}] = useUpdateFaqMutation()
    const [createFaq,{isLoading:createLoading}] = useCreateFaqMutation()
    const [picToUpload,setPicToUpload] = useState(null);
    const [picPreview,setPicPreview] = useState(null);
    const {id} = useParams();
    const fileInputRef = useRef(null);
   
 
  const handleChange=(e)=>{
      const {name,value} = e.target;
        setForm({...form,[name]:value});
  }

    useEffect(()=>{
             fetchDomains({getDomains,setDomains})
            },[])
            
            useEffect(()=>{
             if(domain){
            
               fetchSubdomains({getSubdomains,setSubdomains,id:domain})
             }
            },[domain])
            
        
            
            useEffect(()=>{
            
              if(!domain || !domains?.some((d)=>d?._id === domain)){
            
              
              if(domains?.length >0 ){
                dispatch( setDomain(domains[0]?._id))
              }
            }
            
            },[domains])
            
            useEffect(()=>{
            
              if(!subdomain || !subdomains?.some((sd)=>sd?._id === subdomain)){
            
            
            if(subdomains?.length >0 ){
            
             dispatch( setSubdomain(subdomains[0]?._id))
            
            }
            }
            
            },[subdomains])



  const handleGetFaqById = async()=>{
    try {
        const data = await getFaqById(id).unwrap();
        setForm({
           question: data?.faq?.question,
           answer: data?.faq?.answer,
        });

    } catch (error) {
        getError(error)
    }
  }
 

  const handleSubmit = async(e)=>{
    e.preventDefault()
    try {
        const data =  id? await updateFaqById({id,data:{...form,subdomain_reference:subdomain}}).unwrap() : await createFaq({...form,subdomain_reference:subdomain}).unwrap();
       
        toast.success(data?.message)
        navigate(-1);

    } catch (error) {
        getError(error)
    }
  }

  useEffect(()=>{
    if(id){
        handleGetFaqById()
    }
  },[id]);

  return (
    <MotionDiv>
         <h3 style={{color:'var(--dark-color)'}}>{id?'Edit':'Add'} Faq</h3>

         <Row>
        <Col sm={4} >
            <FormField
            label={" Domain:"}
            type={"select"}
            name={'domain'}
            value={domain}
            onChange={(e)=>{
              dispatch(unsetOptions())
              dispatch(setDomain(e.target.value))
      
          }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col sm={4} >
          <FormField
            label={" Speciality:"}
            type={"select"}
            name={'subdomain'}
            disabled={!domain}
            value={subdomain}
            onChange={(e)=>{
              dispatch(unsetTopic())
              dispatch(unsetSubtopic())
              dispatch(setSubdomain(e.target.value))
      
          }}    
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
                value: item?._id,
            }))}
          />
        </Col>
       
        </Row>


         <Form className='mx-auto w-100' onSubmit={handleSubmit}>
            <Row className='my-3'>
                <Col>
              <FormField
            type={'text'}
              name={'question'}
              label={'Question'}
              placeholder={'Question'}
              value={form?.question} 
              onChange={handleChange}     
            />
              </Col>
              </Row>
            
            <FormField
              as={'textarea'}
              rows={10}
              name={'answer'}
              label={'Answer'}
              placeholder={'Answer goes here...'}
              value={form?.answer} 
              onChange={handleChange}     
            />
            <Row>
                <Col className='text-center'>
                <Button variant="transparent" onClick={()=>navigate(-1)}  className='del-btn mx-2'>Cancel</Button>
                
                <Button variant='transparent' type='submit' disabled={createLoading || updateLoading} className='add-btn mx-2'>
                  {createLoading || updateLoading ?
                <Spinner size='sm'/>
                :
                 'Save Details'
                }
                    </Button>
                </Col>
            </Row>
         </Form>
    </MotionDiv>
  )
}

export default AddEditFaq