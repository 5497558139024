import React, { useEffect, useState } from 'react'
import { CustomTable, MotionDiv } from '../../components'
import { DeleteButton, EditButton } from '../../components/layout/CustomTable'
import { useNavigate } from 'react-router-dom';
import { useDeleteFaqByIdMutation, useGetDomainsMutation, useGetFaqsMutation, useGetSubdomainsMutation } from '../../features/apiSlice';
import { getError } from '../../utils/error';
import { Col, Row } from 'react-bootstrap';
import FormField from '../../components/layout/FormField';
import { useDispatch, useSelector } from 'react-redux';
import { selectOptions, setDomain, setSubdomain, unsetOptions, unsetSubtopic, unsetTopic } from '../../features/optionSlice';
import { fetchDomains, fetchSubdomains } from '../../utils/apis';
import ModalTemplate from '../../components/modals/ModalTemplate';
import { toast } from 'react-toastify';

function FaqPage({subdomain}) {

     const navigate = useNavigate()
       const [getFaqs,{isLoading}] = useGetFaqsMutation();
    const [deleteFaq,{isLoading:deleteLoading}] = useDeleteFaqByIdMutation();
      
       const [faqs,setFaqs] = useState([]);
 
         const [selectedFaq,setSelectedFaq] = useState(null)
    
     const [showCancelModal,setShowCancelModal] = useState(false);
        const handleShowCancelModal = ()=>setShowCancelModal(true);
      const handleHideCancelModal = ()=>setShowCancelModal(false);
        const [curPage, setCurPage] = useState(1);
        const [resultPerPage, setResultPerPage] = useState(5);
       
      
        const curPageHandler = (p) => setCurPage(p);
     
        const numOfPages = Math.ceil(1);
        console.log("no of Page", numOfPages, resultPerPage);
      
        const column = [
            "No.",
            "FAQ",
            "Actions"
          ];
    
         
    
    
          const fetchFaqs = async()=>{
            try {
                const data = await getFaqs({id:subdomain}).unwrap();
                console.log(data);
                setFaqs(data?.faqs);
            } catch (error) {
              getError(error)
            }
          }
    
        const handleDeleteFaq = async()=>{
          try {
            const data = await deleteFaq(selectedFaq?._id).unwrap();
            toast.success(data?.message);
            handleHideCancelModal();
            setSelectedFaq(null)
            fetchFaqs();
        
          } catch (error) {
            getError(error)
          }
        }
    

          useEffect(()=>{
           if(subdomain){
          
             fetchFaqs()
           }
          },[subdomain])
          
        

  return (
<div className='my-5'>
        {/* <h3>Content Management</h3> */}
       
        <CustomTable
          loading={isLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={false}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
        //    search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          title="FAQs"

          isCreateBtn={true}
          createBtnProps={{
            text: 'New FAQ.',
            
            createURL: '/admin/content-management/add-faq',
          }}
        >
          {faqs?.length > 0 &&
            faqs?.map((faq, i) => (
              <tr key={faq?._id} className="odd">
                <td className="text-center">{i + 1}</td>
                <td>{faq?.question}</td>
            
                <td className="text-center">
                  <EditButton
                    onClick={() => navigate(`/admin/content-management/edit-faq/${faq?._id}`)}
                  />
                   <DeleteButton onClick={()=>{
                                      setSelectedFaq(faq);
                                      handleShowCancelModal();
                                    }}
                  /> 

                </td> 
              </tr>
            ))}
        </CustomTable>


        <ModalTemplate
    title={"Are you sure you want to delete this faq?"}
    src={'/icons/question-red.png'}
    onDiscard={handleHideCancelModal}
    show={showCancelModal}
    onHide={handleHideCancelModal}
    onConfirm={handleDeleteFaq}
    loading={deleteLoading}
    deleteBtn={true}
/>


    </div>
      )
}

export default FaqPage