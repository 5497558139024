import React, {  useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";


import Skeleton from "react-loading-skeleton";

import { useDispatch, useSelector } from "react-redux";

import HomeCountCard from "../components/layout/HomeCountCard";
import { MotionDiv } from "../components";
import { imgAddr, useGetAmountDataMutation, useGetDashboardDataMutation, useGetDomainsMutation, useGetSubdomainsMutation } from "../features/apiSlice";
import { getError } from "../utils/error";
import DateTimeFormater from "../components/layout/DateTimeFormatter";
import { selectOptions, setDomain, setSubdomain, unsetOptions, unsetSubtopic, unsetTopic } from "../features/optionSlice";
import FormField from "../components/layout/FormField";
import { fetchDomains, fetchSubdomains } from "../utils/apis";
import { PieChart } from "@mui/x-charts";
import Title from "../components/layout/Title";



export default function Dashboard() {
  

const dispatch = useDispatch();

const [data,setData] = useState(null);
const [graphData,setGraphData] = useState(null);
const [getDashboardData,{isLoading}] = useGetDashboardDataMutation();
const [getAmountData,{isLoading:amountLoading}] = useGetAmountDataMutation();
const {domain,subdomain} = useSelector(selectOptions);
const [domains,setDomains] = useState([]);
const [subdomains,setSubdomains] = useState([]);
const [getDomains,{isLoading:domainLoading}] = useGetDomainsMutation();
  const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();
const fetchData = async()=>{
  try {
    const data = await getDashboardData().unwrap();

    setData(data);
  } catch (error) {
    getError(error)
  }
}
const fetchGraphData = async()=>{
  try {
    const data = await getAmountData(subdomain).unwrap();
console.log(data);
    setGraphData(data?.data);
  } catch (error) {
    getError(error)
  }
}

useEffect(()=>{

  if(!domain || !domains?.some((d)=>d?._id === domain)){

  
  if(domains?.length >0 ){
    dispatch( setDomain(domains[0]?._id))
  }
}

},[domains])

useEffect(()=>{

  if(!subdomain || !subdomains?.some((sd)=>sd?._id === subdomain)){


if(subdomains?.length >0 ){

 dispatch( setSubdomain(subdomains[0]?._id))

}
}

},[subdomains])

useEffect(()=>{
  fetchDomains({getDomains,setDomains})
 },[])

 useEffect(()=>{
  if(domain){

    fetchSubdomains({getSubdomains,setSubdomains,id:domain})
  }
 },[domain])

useEffect(()=>{
  fetchData();
},[])

useEffect(()=>{
  if(subdomain){

    fetchGraphData();
  }
},[subdomain])

  return (
    <MotionDiv>
      {/* <h3 style={{ color: 'var(--secondary-color)'}} className='my-2'>Dashboard</h3> */}
      <Title>Dashboard</Title>
   <Row>
    <Col>
              <Row>
                            <Col md={3}>

                                <HomeCountCard title={'Total Domains'} monthValue={data?.cardsData?.currentMonthDomain ||0} yearValue={data?.cardsData?.currentYearDomain ||0} img={'/icons/domain.png'}/>

                            </Col>
                            <Col md={3}>

                                <HomeCountCard title={'Total Professors'} monthValue={data?.cardsData?.currentMonthProfessor ||0} yearValue={data?.cardsData?.currentYearProfessor ||0} img={'/icons/professor.png'} />

                            </Col>
                            <Col md={3}>

                                <HomeCountCard title={'Total Subscribers'} monthValue={data?.cardsData?.currentMonthSubscription ||0} yearValue={data?.cardsData?.currentYearSubscription ||0} img={'/icons/user.png'}/>

                            </Col>
                            <Col md={3}>

                                <HomeCountCard title={'Total Amount'} monthValue={`£${data?.cardsData?.currentMonthAmount ||0}`} yearValue={`£${data?.cardsData?.currentYearAmount ||0}`} img={'/icons/money.png'}/>

                            </Col>
              </Row>
              <Row className="my-3">
                
                <Col md={6}>
                              <div className='rounded-4 shadow bg-white p-3 h-100'>
                              <h5 style={{ color: 'var(--secondary-color)'}}>Newly Added Domains</h5>
                              <p className='w-100 border-bottom d-flex justify-content-between'>
                                      <span className='me-auto fw-bold'>
                                      Domain Name</span>
                                     <span style={{marginLeft:'auto'}} className=' text-end fw-bold'>
                                      Created At</span>
                                     </p>
                                    {data?.data?.domains && data?.data?.domains?.map((domain,i)=>(


                                    <p className='w-100 d-flex my-4 py-1 border-bottom justify-content-between'>
                                      <span className='me-auto'>
                                      {domain?.domain_name}</span>
                                     <span style={{marginLeft:'auto',fontSize:'12px'}} className=' text-end'><DateTimeFormater newLine={true} timeReq={false} createdAt={domain?.createdAt}/></span>
                                     </p>
                                    ))}

                              </div>
                              
                </Col>
                <Col>
                              <div className='rounded-4 shadow bg-white p-3 h-100'>
                              <h5 style={{ color: 'var(--secondary-color)'}}>New Professors</h5>
                              <p className='w-100 d-flex border-bottom justify-content-between'>
                                      <span className='me-auto fw-bold'>
                                      Prof. Name</span>
                                     <span style={{marginLeft:'auto'}} className=' text-end fw-bold'>
                                      Created At</span>
                                     </p>
                                    {data?.data?.professors && data?.data?.professors?.map((prof,i)=>(


                                    <p className='w-100 border-bottom d-flex justify-content-between'><span className='me-auto'><Image src={imgAddr+prof?.profile_url} style={{height:'40px',aspectRatio:'1/1',borderRadius:'50%'}}/> {prof?.first_name +' '+ prof?.last_name}</span>   <span style={{marginLeft:'auto',fontSize:'12px'}} className=' text-end'><DateTimeFormater timeReq={false} createdAt={prof?.createdAt}/></span></p>
                                    ))}

                              </div>
                              </Col>
              </Row>
</Col>
</Row>

    
    <Row className="my-3">
      <Col md={6}>
      <div className="shadow rounded-4 p-3 h-100 overflow-hidden">
   <Row>
   <Col >
            <FormField
            label={"Domain:"}
            type={"select"}
            name={'domain'}
            value={domain}
          
            onChange={(e)=>{
              dispatch(unsetOptions())
              dispatch(setDomain(e.target.value))
      
          }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Speciality:"}
            type={"select"}
            name={'subdomain'}
            // disabled={!domain}
            value={subdomain}
            onChange={(e)=>{
              dispatch(unsetTopic())
              dispatch(unsetSubtopic())
              dispatch(setSubdomain(e.target.value))
      
          }}            
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
                value: item?._id,
            }))}
          />
        </Col>

   </Row>
<Row>
  <Col className="d-flex justify-content-center">
  {graphData?.Annually || graphData?.Quarterly || graphData?.Monthly ?
  <>
  <div className="text-center">
  <PieChart
  
  series={[
    {
      data: [
        { id: 0, value: graphData?.Annually, label: 'Annually' },
        { id: 1, value: graphData?.Quarterly, label: 'Quarterly' },
        { id: 2, value: graphData?.Monthly, label: 'Monthly' },
      ],
      innerRadius:30,
      cornerRadius:5,
      
    },
  ]}
  width={400}
  height={200}
/>
  <p className="m-0">No. Of Transactions Under All Plans</p>
</div>
</>
:
<p className="my-5">No Transactions Yet</p>
}
  </Col>
</Row>
   
      </div>
      </Col>
    <Col>
                              <div className='rounded-4 shadow bg-white p-3 h-100'>
                              <h5 style={{ color: 'var(--secondary-color)'}}>New Tickets</h5>
                              <p className='w-100 border-bottom d-flex justify-content-between'>
                                      <span className='me-auto fw-bold'>
                                      User Name</span>
                                     <span style={{marginLeft:'auto'}} className=' text-end fw-bold'>
                                      Raised At</span>
                                     </p>
                                    {data?.data?.tickets && data?.data?.tickets?.map((ticket,i)=>(


                                    <p className='w-100 border-bottom d-flex justify-content-between'><span className='me-auto'><Image src={imgAddr+ticket?.from?.profile_url} style={{height:'40px',aspectRatio:'1/1',borderRadius:'50%'}}/> {ticket?.from?.first_name +' '+ ticket?.from?.last_name}</span>   <span style={{marginLeft:'auto',fontSize:'12px'}} className=' text-end'><DateTimeFormater newLine={true} createdAt={ticket?.createdAt}/></span></p>
                                    ))}

                              </div>
                              </Col>
    </Row>


    </MotionDiv >
  );
}
